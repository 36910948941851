<template>
  <div class="p_b__3 pb-5">
    <video
      @loadedmetadata="initializeVideo"
      preload="metadata"
      hidden
      ref="video"
      v-if="videoData[videoIndex]"
      :src="videoData[videoIndex].src"
    ></video>
    <b-container fluid>
      <b-row
        class="text-white py-3"
        style="background-color: #021d31"
        align-v="center"
      >
        <b-col
          style="font-size: 2rem"
          class="pl-4 py-3 border-white"
          :class="{ 'border-right': $route.name !== rankingRoute.name }"
        >
          <div class="d-flex align-items-start justify-content-between">
            <div>{{ projectName }}</div>
            <div v-if="isProjectSelectionVisible" class="ml-3">
              <project-selection />
            </div>
          </div>
        </b-col>
        <b-col
          v-if="displayInnerNavigation && !comparison"
          align-self="start"
          class="pl-4 py-0"
        >
          <div class="h5 mt-2 font-weight-bold">
            {{ $t("page.results.mediaDetail") }}
          </div>

          <b-row align-h="start" style="font-size: 0.8rem" class="mt-1">
            <b-col cols="auto" class="mr-4">
              <div>{{ $t("client") }}</div>
              <div v-if="videoData[videoIndex]" class="font-weight-bold">
                {{ videoData[videoIndex].client }}
              </div>
            </b-col>
            <b-col cols="auto" class="mr-4">
              <div>{{ $t("country") }}</div>
              <div v-if="videoData[videoIndex]" class="font-weight-bold">
                {{ videoData[videoIndex].country }}
              </div>
            </b-col>
            <b-col cols="auto" class="mr-4">
              <div>{{ $t("duration") }}</div>
              <div class="font-weight-bold">{{ activeVideoDuration }}</div>
            </b-col>
            <b-col cols="auto" class="mr-4">
              <div>{{ $t("category") }}</div>
              <div v-if="videoData[videoIndex]" class="font-weight-bold">
                {{ videoData[videoIndex].category }}
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <b-navbar variant="white">
      <b-navbar-nav
        class="text-uppercase mr-4"
        style="font-weight: 700; font-size: 0.8rem"
      >
        <b-nav-item active-class="text-red" replace :to="rankingRoute" v-if="!isIncontext">
          {{ $t("page.results.emotionalRanking") }}
        </b-nav-item>
        <b-nav-item v-if="!video.in_context_metrics" active-class="text-red" replace :to="scoreRoute">
          {{ $t("page.results.metricsRanking") }}
        </b-nav-item>

        <b-nav-item v-if="video.in_context_metrics" active-class="text-red" replace :to="inContenxtRoute">
           {{ $t(`page.results.incontextMetrics`) }}
        </b-nav-item>

        <b-nav-item active-class="text-red" replace :to="graphRoute">
          {{ $t("page.results.chartRanking") }}
        </b-nav-item>
      </b-navbar-nav>
      <!-- <b-button
        v-if="!guestMode"
        @click="getShareLink()"
        v-b-modal.share-modal
        class="text-white ml-5"
        style="font-weight: 400; font-size: 0.8rem"
        variant="primary"
        size="sm"
      >
        {{ $t("page.results.shareResults") }}
      </b-button> -->
      <b-nav-text
        v-if="$route.name !== rankingRoute.name"
        class="ml-auto total-complete-text text-primary pr-2"
      >
        {{ $t("page.results.totalComplete") }}
        <span v-if="videoData[videoIndex]" style="font-weight: 700">{{
          videoData[videoIndex].totalComplete
        }}</span>
        <span v-else style="font-weight: 700">~</span>
      </b-nav-text>

      <b-nav-text class="ml-auto pr-2" v-else>
        <b-link @click="changeDisplay('grid')">
          <img
            :style="
              display == 'grid'
                ? 'opacity:1 !important'
                : 'opacity:0.5 !important'
            "
            width="30"
            src="../assets/chart-icons/grid.svg"
            class="mr-2"
          />
        </b-link>
        <b-link @click="changeDisplay('list')">
          <img
            :style="
              display == 'list'
                ? 'opacity:1 !important'
                : 'opacity:0.5 !important'
            "
            width="30"
            src="../assets/chart-icons/list.svg"
            class="mr-2"
          />
        </b-link>
      </b-nav-text>
    </b-navbar>

    <!-- SHARE MODAL -->
    <b-modal
      :title="$t('page.results.shareResults')"
      id="share-modal"
      header-class="py-2 align-items-center text-small"
      hide-footer
    >
      <b-input-group>
        <b-form-input
          style="font-size: 0.8rem"
          ref="share-input"
          :value="shareLink"
          readonly
          size="sm"
        >
        </b-form-input>

        <template v-slot:append>
          <b-button style="font-size: 0.8rem" @click="copyLink()" size="sm">
            {{ $t("button.copy") }}
          </b-button>
        </template>
      </b-input-group>
    </b-modal>
    <div>
      <router-view> </router-view>
    </div>
  </div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import { mapState, mapMutations,mapGetters } from "vuex";
import Config from "../plugins/Config";
import ProjectSelection from "../components/Results/ProjectSelection";

export default {
  components: {
    ProjectSelection,
  },
  data() {
    return {
      analysis: null,
      active: "score",
      shareLink: "",
      activeVideo: null,
      activeVideoDuration: "N/A",
    };
  },

  async created() {
    am4core.addLicense(Config.value("amchartsLicense"));

    // try {
    //   console.log(this.$route);
    //   await this.$store.dispatch("analysis/retrieveVideoData", {
    //     projectId: this.$route.params.projectId,
    //     clientId: this.$store.getters["auth/getClientId"]
    //   });
    //   await this.$store.dispatch("analysis/retrieveProjectVideos", {
    //     projectId: this.$route.params.projectId
    //   });
    //   await this.$store.dispatch("project/retrieveProjectByClientId", {
    //     projectId: this.$route.params.projectId
    //   });
    // } catch (error) {
    //   console.log(error);
    // }
  },

  computed: {
    ...mapState("analysis", [
      "comparison",
      "guestMode",
      "videoIndex",
      "display",
      "videoData",
      "projectName",
    ]),

    ...mapState("project", ["activeProject"]),

    ...mapGetters("analysis",["video"]),

    showMetrics() {
      return this.activeProject.step !== -1;
    },

    isIncontext(){
      return this.$store.getters["analysis/isIncontext"];
    },

    rankingRoute() {
      if (this.guestMode) {
        return { name: "shareRanking" };
      } else {
        return { name: "ranking" };
      }
    },

    scoreRoute() {
      return this.guestMode ? { name: "shareScore" } : { name: "score" };
    },
    inContenxtRoute(){
      return this.guestMode ? {name:'shareInContextScore'}:{name:'inContextScore'};
    },

    graphRoute() {
      return this.guestMode ? { name: "shareGraph" } : { name: "graph" };
    },

    displayInnerNavigation() {
      return (
        this.$route.name === this.scoreRoute.name ||
        this.$route.name === this.graphRoute.name ||
        this.$route.name === this.inContenxtRoute.name
      );
    },

    isProjectSelectionVisible() {
      return !this.guestMode;
    },
  },

  methods: {
    ...mapMutations("analysis", {
      changeDisplay: "SET_DISPLAY",
    }),

    async getShareLink() {
      const analysisObject = await this.$store.dispatch(
        "analysis/retrieveShareLink",
        {
          clientId: this.$store.getters["auth/getClientId"],
          projectId: this.$route.params.id,
        }
      );

      const hostname = location.hostname;
      const port = location.port ? ":" + location.port : "";

      this.shareLink = `${hostname}${port}/share/project/${analysisObject.projectId}/${this.$store.getters["auth/getClientId"]}/${analysisObject.token}`;
    },

    copyLink() {
      this.$refs["share-input"].select();
      document.execCommand("copy");
      this.$bvModal.hide("share-modal");
    },

    initializeVideo() {
      this.activeVideo = this.$refs.video;

      if (this.activeVideo) {
        const time = this.activeVideo.duration;
        let minutes = Math.floor(time / 60);
        let seconds = Math.floor(time - minutes * 60);
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        this.activeVideoDuration = `${minutes}:${seconds}`;
      } else this.activeVideoDuration = "N/A";
    },

    goTo(route) {
      if (this.comparison) {
        this.$store.commit("analysis/SET_CURRENT_VIDEO_INDEX", 0);
      }
      this.$store.commit("analysis/SET_COMPARISON", false);
      this.$router.push(route);
    },
  },
};
</script>

<style scoped>
.content {
  padding-left: 24px;
  padding-right: 10px;
  padding-top: 20px;
}

.total-complete-text {
  font-size: 1.1rem;
}

.outer-navbar {
  z-index: 1;
  position: relative;
}

.inner-navbar::before {
  content: "";
  top: -7px;
  left: 30px;
  width: 0;
  height: 0;
  position: absolute;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 10px solid #f8f9fa;
}

.inner-navbar {
  position: absolute;
  top: 55px;
  left: 158px;
}

.underline > .text {
  padding-bottom: 4px;
  border-bottom: 2px solid var(--red);
}

.route-active {
  color: var(--red);
}

.route-link:hover {
  cursor: pointer;
  opacity: 0.9;
}

.text-small {
  font-size: 0.8rem !important;
  font-weight: 700 !important;
}

.active-link {
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-color: var(--red);
  text-decoration-thickness: 2px;
  text-underline-offset: 5px;
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 650px) {
  .inner-navbar::before {
    content: "";
    top: 100%;
    width: 0;
    height: 0;
    border: none;
  }

  .inner-navbar {
    top: 100%;
    left: 26px;
  }
}
</style>
